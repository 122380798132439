import { memo, useRef, useState } from 'react';
import styled from 'styled-components';

const VideoElement = styled.video`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

/**
 * VideoContainer for 16/9 frame. Default 640x360
 * width is:
 * - up to md(768): 640 max
 * - larger: 768 max
 */
const VideoContainer = styled.div<{ width?: number }>`
  --width: ${({ width }) => Math.min(width ?? 640, 640)}px;
  position: relative;
  overflow: hidden;
  width: var(--width);
  height: 0;
  padding-bottom: 56.25%;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    --width: ${({ width }) => Math.min(width ?? 768, 768)}px;
  }
`;

const VideoOverlay = styled.div<{ blurredPoster?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  ${({ blurredPoster }) =>
    blurredPoster &&
    `
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1.5rem);
  `}
  cursor: pointer;
`;

const PlayButtonContainer = styled.div`
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.focus.strong};
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
`;

const PlayButton = () => (
  <PlayButtonContainer>
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4766 12.2075V46.0013L46.0518 29.1044L19.4766 12.2075Z" fill="#0097A9" />
    </svg>
  </PlayButtonContainer>
);

function typeofVideo(src: string): string {
  const extension = src.split('.').reverse()[0].toLowerCase();

  return `video/${extension}`;
}

type NonEmptyArray<T> = [T, ...T[]];

/**
 * default width is 640px
 */
export interface VideoPlayerProps {
  source: NonEmptyArray<{ src: string }>;
  poster?: string;
  width?: number;
}

/**
 *
 * @param {SourceElement[]} source the source urls
 * @param {string} poster for the video. Optional
 * @param {number} width of the video tag. Optional. Default = 640
 * @returns {VideoPlayer} a Video component
 */
export const VideoPlayer = memo(({ source, width, poster }: VideoPlayerProps) => {
  const [isVideoPlaying, setPlayingState] = useState(false);
  const [hasVideoStarted, setStartedState] = useState(false);
  const videoElement = useRef<HTMLVideoElement>(null);

  const playVideo = () => {
    if (videoElement.current) {
      videoElement.current.play();
      setPlayingState(true);
      if (!hasVideoStarted) {
        setStartedState(true);
      }
    }
  };

  return (
    <VideoContainer width={width}>
      <VideoElement
        ref={videoElement}
        controls={isVideoPlaying}
        controlsList="nodownload"
        poster={poster}
        preload="metadata"
        autoPlay={false}
        onPause={() => setPlayingState(false)}
        onPlay={() => setPlayingState(true)}
      >
        {source.map(({ src }) => (
          <source key={`video-src-${src}`} src={src} type={typeofVideo(src)} />
        ))}
        Your browser does not support the video tag.
      </VideoElement>
      {!isVideoPlaying && (
        <VideoOverlay onClick={playVideo} blurredPoster={poster == null && !hasVideoStarted}>
          <PlayButton />
        </VideoOverlay>
      )}
    </VideoContainer>
  );
});

VideoPlayer.displayName = 'VideoPlayer';
