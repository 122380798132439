import type { ReactNode } from 'react';

import { richTextToReactNode } from '@seuk/cms-api-client';
import { deserializeCardInterface, deserializeStepDescription } from '@seuk/cms-mappers';
import { EMarketingSectionType } from '@seuk/cms-mappers/enum';
import type { MarketingSectionData, VideoMarketingSectionData } from '@seuk/cms-mappers/serializers';
import { Container, SectionGroup, ZebraSection } from '@seuk/design-system/atoms';
import {
  FAQList,
  MarketingCards,
  MarketingProductsCards,
  StepsAsArrows,
  TableSection,
  TextSection,
  USPs,
  VideoSection,
} from '@seuk/design-system/organisms';
import { assertNever } from '@seuk/util';

export function getWrappableComponentForSection(
  section: Exclude<MarketingSectionData, VideoMarketingSectionData>
): ReactNode | undefined {
  switch (section.type) {
    case EMarketingSectionType.TableSection:
      return <TableSection {...section.props} />;

    case EMarketingSectionType.TextSection:
      return <TextSection {...section.props} description={richTextToReactNode(section.props.description)} />;

    case EMarketingSectionType.USPListSection:
      return <USPs {...section.props} items={section.props.items.map(deserializeCardInterface)} />;

    case EMarketingSectionType.InformationalCardListSection:
      return <MarketingCards {...section.props} cards={section.props.cards.map(deserializeCardInterface)} />;

    case EMarketingSectionType.ProductsCardListSection:
      return <MarketingProductsCards {...section.props} cards={section.props.cards.map(deserializeCardInterface)} />;

    case EMarketingSectionType.StepsSection:
      return <StepsAsArrows {...section.props} steps={section.props.steps.map(deserializeStepDescription)} />;

    case EMarketingSectionType.GroupSection:
      return (
        <SectionGroup key={`${section.id}_inner`} spacing={section.props.itemsSpacing}>
          {section.props.items.map((item) => (
            <div key={`${item.id}`}>{getWrappableComponentForSection(item)}</div>
          ))}
        </SectionGroup>
      );

    case EMarketingSectionType.FAQListSection:
      return <FAQList {...section.props} />;

    default:
      return assertNever(section);
  }
}

export const MarketingSection = (section: MarketingSectionData & { sectionsSpacing?: number }) => {
  if (section.type === EMarketingSectionType.VideoSection) {
    return <VideoSection {...section.props} />;
  }

  const wrappableComponent = getWrappableComponentForSection(section);

  if (wrappableComponent !== undefined) {
    return (
      <ZebraSection
        {...{
          bgColor: section.type === EMarketingSectionType.GroupSection && section.props.backgroundColor,
          spacing: section.sectionsSpacing,
        }}
      >
        <Container>{wrappableComponent}</Container>
      </ZebraSection>
    );
  }

  return null;
};
