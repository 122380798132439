import dynamic from 'next/dynamic';
import { memo } from 'react';
import styled from 'styled-components';

import { NextImage, RichTextWrapper, Row, Typography } from '../../atoms';
import type { CardInterface } from '../marketing-cards';

const DynamicAnimatedIcon = dynamic(
  () => import('../../atoms/icon/animated-icon').then((module) => module.AnimatedIcon),
  {
    // TODO: after upgrading to react 18 use option suspense: true
    ssr: false,
  }
);

export interface USPsProps {
  items: CardInterface[];
  title?: string;
}

const USP = styled.div<{ mobileReverse?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(8)};
  flex: 1;
  display: flex;
  flex-direction: ${({ mobileReverse }) => (mobileReverse ? 'row-reverse' : 'row')};
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 17.5rem;
    text-align: center;
    display: block;
  }

  // TODO: This should be styled or have consumer-controlled unique identifier (e.g. id, class) instead
  lord-icon {
    width: 5rem;
    height: 5rem;
    flex: 1;
    display: block;
    margin: auto;
    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${({ theme }) => theme.spacing(5)};
      width: 7rem;
      height: 7rem;
    }
  }
`;
const USPTitle = styled.h3`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.typography.heading[4].normal};
  ${({ theme }) => theme.typography.label.large};
`;
const USPIconWrapper = styled.div`
  position: relative;
  width: 5rem;
  height: 5rem;
  flex: 1;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;
const USPDetails = styled.div`
  flex: 2;
`;

interface IconWrapperProps {
  item: CardInterface;
}

const IconWrapper = ({ item }: IconWrapperProps) => {
  return item.image || item.icon ? (
    <USPIconWrapper>
      <NextImage
        src={item.icon ?? item.image?.src ?? ''}
        fill
        unoptimized={true}
        alt={item.iconAlt ?? item.image?.alt ?? ''}
      />
    </USPIconWrapper>
  ) : null;
};

export const USPs = memo(({ items, title }: USPsProps) => {
  return (
    <div>
      {title && (
        <Typography variant="h2" weight="bold" notch={true}>
          {title}
        </Typography>
      )}
      <Row alignTop={true}>
        {items.map((item, idx) => (
          <USP data-testid="why-shell-answer" key={item.title} mobileReverse={idx % 2 ? true : false}>
            {item.animation ? (
              <DynamicAnimatedIcon
                src={item.animation?.json}
                delay={idx * 500}
                trigger={item.animation?.trigger}
                state={item.animation?.state}
                colors="#1090b8"
              />
            ) : (
              <IconWrapper item={item} />
            )}

            <USPDetails>
              <USPTitle>{item.title}</USPTitle>
              <RichTextWrapper>{item.description}</RichTextWrapper>
            </USPDetails>
          </USP>
        ))}
      </Row>
    </div>
  );
});

USPs.displayName = 'USPs';
