import { memo } from 'react';
import styled from 'styled-components';

import { Container, EmptyZebraSection, ZebraSection } from '../../atoms';
import type { VideoPlayerProps } from '../../molecules';
import { VideoPlayer } from '../../molecules';

const VideoContainer = styled(Container)`
  padding-bottom: 4rem;
  background: ${({ theme }) => theme.palette.background.base};
  display: flex;
  flex-direction: column;
`;
const VideoWrapper = styled.div`
  margin: -${({ theme }) => theme.spacing(14)} auto 0;
  max-width: 100%;
  height: auto;
`;

export type VideoSectionProps = VideoPlayerProps;

export const VideoSection = memo((props: VideoSectionProps) => {
  return (
    <>
      <ZebraSection>
        <VideoContainer>
          <VideoWrapper>
            <VideoPlayer {...props} />
          </VideoWrapper>
        </VideoContainer>
      </ZebraSection>
      <EmptyZebraSection />
    </>
  );
});

VideoSection.displayName = 'VideoSection';
