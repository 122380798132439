import type { ReactNode } from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { Container, Typography } from '../../atoms';

type ImagePath = 1 | 2 | 3 | 4 | 'completed';

export interface StepDescription {
  title: string;
  text: ReactNode;
  imagePath?: ImagePath;
}

export interface StepsAsArrowsProps {
  title?: string;
  steps: StepDescription[];
}

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing(8)};
  ${({ theme }) => theme.typography.heading[3].normal};
  ${({ theme }) => theme.notch};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0 0 ${({ theme }) => theme.spacing(12)};
  }
`;

const Arrow = styled.div<{ first: boolean; even: boolean }>`
  display: flex;
  flex-direction: ${({ even }) => (even ? 'row-reverse' : 'row')};
  margin-top: ${({ theme, first }) => (first ? 0 : theme.spacing(6))};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: ${({ theme, first }) => (first ? 0 : theme.spacing(8))};
    margin-top: 0;
    flex-direction: column;
    flex: 1;
  }
`;

const ImageContainer = styled.div<{ even: boolean }>`
  display: flex;
  min-width: 40%;
  position: relative;
  align-items: center;
  margin-left: ${({ theme, even }) => (even ? theme.spacing(4) : 0)};
  margin-right: ${({ theme, even }) => (even ? 0 : theme.spacing(4))};
  justify-content: ${({ even }) => (even ? 'flex-end' : 'flex-start')};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const TextContainer = styled.div<{ even: boolean }>`
  justify-content: ${({ even }) => (even ? 'flex-end' : 'flex-start')};
`;

const ArrowImage = styled.img`
  display: none;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: inline-block;
    width: min(190px, 100%);
  }
`;

const MobileArrowImage = styled.img<{ even: boolean }>`
  display: block;
  max-width: 100%;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;

const StepTitle = styled.h4`
  ${({ theme }) => theme.typography.heading[4].normal};
  margin: ${({ theme }) => theme.spacing(5)} 0;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 90%;
  }
`;

const BASE_IMAGE_PATH = '/images/steps-as-arrows';

export const StepsAsArrows = memo(({ steps, title }: StepsAsArrowsProps) => (
  <Container>
    {title && <Title>{title}</Title>}
    <ArrowsContainer>
      {steps.map((step, idx) => {
        const even = (idx + 1) % 2 === 0;
        const imageIndex = idx + 1;
        const altText = step.imagePath === 'completed' ? 'completed!' : `step ${step.imagePath || imageIndex}`;

        return (
          <Arrow key={step.imagePath} first={idx === 0} even={even}>
            <ImageContainer even={even}>
              <ArrowImage alt={altText} src={`${BASE_IMAGE_PATH}/step-${step.imagePath || imageIndex}.svg`} />
              <MobileArrowImage
                alt={altText}
                src={`${BASE_IMAGE_PATH}/step-${step.imagePath || imageIndex}-m.svg`}
                even={even}
              />
            </ImageContainer>
            <TextContainer even={even}>
              <StepTitle>{step.title}</StepTitle>
              <Typography variant="basic">{step.text}</Typography>
            </TextContainer>
          </Arrow>
        );
      })}
    </ArrowsContainer>
  </Container>
));

StepsAsArrows.displayName = 'StepsAsArrows';
