import { memo } from 'react';
import styled, { css } from 'styled-components';

import type { RichText } from '@seuk/cms-api-client';
import { richTextToReactNode } from '@seuk/cms-api-client';

import { Typography } from '../../atoms';

const SectionTitle = styled(Typography).attrs({ component: 'h3', variant: 'h2', weight: 'bold' })`
  ${({ theme }) => css`
    text-align: center;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: ${theme.spacing(12)};
  `}
`;

const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-spacing: 0px;

    th,
    td {
      padding: 1rem;

      &:last-child {
        background-color: ${theme.palette.background.base};
      }
    }

    th {
      ${theme.typography.label.large};
    }

    td {
      border-top: 0.5px solid ${theme.palette.border.subtle};
    }
  `}
`;

type CellItem =
  | {
      type: 'string';
      value: string | null;
      id: string;
    }
  | {
      type: 'rich_text';
      value: RichText | null;
      id: string;
    };

type ColumnsAlignment = 'center' | 'left' | 'right' | 'justify' | 'char' | undefined;

export interface TableSectionProps {
  readonly title?: string;
  readonly headers?: CellItem[];
  readonly tableRows?: CellItem[][];
  readonly columnsAlignment?: ColumnsAlignment[];
}

function renderCellValue(item: CellItem) {
  if (item.type === 'string') {
    return item.value;
  }
  if (item.type === 'rich_text' && item.value) {
    return richTextToReactNode(item.value);
  }
  return null;
}

const TableSectionComponent = ({ title, headers, tableRows, columnsAlignment }: TableSectionProps) => {
  return (
    <div>
      {title && <SectionTitle>{title}</SectionTitle>}

      <Table>
        {headers && (
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th align={columnsAlignment?.[index] ?? 'left'} key={header.id}>
                  {renderCellValue(header)}
                </th>
              ))}
            </tr>
          </thead>
        )}

        {tableRows && (
          <tbody>
            {tableRows?.map((rowFields, rowIndex) => (
              <tr key={rowIndex}>
                {rowFields.map((item, columnIndex) => (
                  <td align={columnsAlignment?.[columnIndex] ?? 'left'} key={item.id}>
                    {renderCellValue(item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export const TableSection = memo(TableSectionComponent);
