import DOMPurify from 'isomorphic-dompurify';
import styled, { css } from 'styled-components';

import type { ButtonComponentEntry, MarketingPageFields } from '@seuk/cms-api-client';
import { ButtonLink } from '@seuk/design-system/molecules';

const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    background-color: ${theme.palette.background.surface};
    padding: ${theme.spacing(8)};
    max-width: 37.5rem;
    box-sizing: border-box;
    border-radius: ${theme.radiusLg};
    ${theme.breakpoints.up('lg')} {
      align-self: end;
      width: 37vw;
    }
  `}
`;

const DEFAULT_BACKGROUND_COLOR: MarketingPageFields['introBackgroundColor'] = 'Grey';

const HeroTitle = styled.h1<{ backgroundColor?: MarketingPageFields['introBackgroundColor'] }>`
  white-space: pre-wrap;
  ${({ theme, backgroundColor = DEFAULT_BACKGROUND_COLOR }) => css`
    ${theme.typography.heading[1].demi}
    margin: 0;
    margin-bottom: ${theme.spacing(4)};
    color: ${backgroundColor === DEFAULT_BACKGROUND_COLOR
      ? theme.palette.focus.strong
      : theme.palette.background.surface};
  `}
`;

const GetStartedButton = styled(ButtonLink)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(4)};
    text-align: center;
  `}
`;

interface HeroDetailsProps {
  id?: string;
  title: string;
  subtitle: string;
  link?: ButtonComponentEntry;
}

export const HeroDetails = ({ id, title, subtitle, link }: HeroDetailsProps) => {
  const sanitizedTitle = DOMPurify.sanitize(title, { ALLOWED_TAGS: ['b', 'br'] });
  return (
    <Card id={id}>
      <HeroTitle dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
      <p>{subtitle}</p>
      {link && (
        <GetStartedButton color="tertiary" key={link?.fields.title} href={link?.fields.url}>
          {link?.fields.title}
        </GetStartedButton>
      )}
    </Card>
  );
};
